import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-e6419fcc")
const _hoisted_1 = { class: "item__header" }
const _hoisted_2 = { class: "item__header-title" }
const _hoisted_3 = { class: "item__body u-mt--1 u--ml--3" }
const _hoisted_4 = {
  key: 0,
  class: "item__footer"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['notification-item', `notification-item--${_ctx.type}`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Icon, {
        name: _ctx.type === 'error' ? 'icon_cross' : 'icon_checkmark',
        class: "item__header-icon u-mr--1"
      }, null, 8, ["name"]),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.message), 1),
    (_ctx.isMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Button, { onClick: _ctx.onChat }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.proceed')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}