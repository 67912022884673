// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('common.virtual_terminals'),
    route: { name: 'terminals$list' },
  },
  {
    label: i18n.t('misc.virtual_terminal_id'),
    route: { name: 'terminals$detail' },
  },
  {
    label: i18n.t('misc.new_virtual_terminal'),
    route: { name: 'terminals$create' },
  },
];

export default [
  {
    path: '/terminals',
    name: 'terminals$list',
    component: () => import('@/apps/terminals/views/List/List.vue'),
    meta: {
      breadCrumb: [breadCrumb[0]],
      title: i18n.t('common.virtual_terminals'),
    },
  },
  {
    path: '/terminals/:id',
    name: 'terminals$detail',
    component: () => import('@/apps/terminals/views/Detail/Detail.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('misc.virtual_terminal'),
    },
  },
  {
    path: '/terminals/:id/edit',
    name: 'terminals$edit',
    component: () => import('@/apps/terminals/views/Edit/Edit.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('misc.virtual_terminal'),
    },
  },
  {
    path: '/terminals/create',
    name: 'terminals$create',
    component: () => import('@/apps/terminals/views/Create/Create.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2]],
      title: i18n.t('misc.new_virtual_terminal'),
    },
  },
];
