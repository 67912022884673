var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import ChannelHandler from './channel-handler';
import { ChannelConnector, bus } from '@brskl/core';
import { ElNotification } from 'element-plus';
var instance;
var channelConnector;
var isWebSocketDebugEnabled = window.location.href.includes('debug-websocket=1');
var showDebugNotification = function showDebugNotification(title, message) {
  if (message === void 0) {
    message = '';
  }
  if (!isWebSocketDebugEnabled) return;
  ElNotification({
    title: title,
    message: message,
    duration: 0
  });
};
var addChannelConnectorDebugListeners = function addChannelConnectorDebugListeners() {
  channelConnector.instance.addEventListener('close', function (event) {
    showDebugNotification('Socket Closed', {
      code: event.code,
      reason: event.reason,
      wasClean: event.wasClean
    });
    console.log('Socket Closed', event);
  });
  channelConnector.instance.addEventListener('error', function (event) {
    showDebugNotification('Socket Error', event);
    console.log('Socket Error', event);
  });
  channelConnector.instance.addEventListener('message', function (event) {
    var _a;
    showDebugNotification('Socket Message', {
      data: event.data,
      url: (_a = event.currentTarget) === null || _a === void 0 ? void 0 : _a.url
    });
    console.log('Socket Message', event);
  });
};
var openCallback = function openCallback(event) {
  if (isWebSocketDebugEnabled) {
    showDebugNotification('Socket Opened');
    console.log('Socket Opened', event);
    addChannelConnectorDebugListeners();
  }
};
function wait() {
  return new Promise(function (resolve) {
    var timer = setInterval(function () {
      if (instance.channel) {
        resolve(null);
        clearInterval(timer);
      }
    }, 100);
  });
}
export function init(endpoint) {
  if (instance) return;
  instance = new ChannelHandler({
    endpoint: endpoint,
    openCallback: openCallback
  });
  channelConnector = new ChannelConnector(instance);
  bus.emit('core$auth/init');
}
export function get() {
  return __awaiter(this, void 0, void 0, function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (instance.channel) return [2 /*return*/, instance.channel];
          return [4 /*yield*/, wait()];
        case 1:
          _a.sent();
          return [2 /*return*/, instance.channel];
      }
    });
  });
}
export var getChannelConnector = function getChannelConnector() {
  return channelConnector;
};