// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('common.write_offs'),
    route: { name: 'write-offs$write-offs' },
  },
  {
    label: `${i18n.t('common.writeoff')} :id`,
    route: { name: 'write-offs$write-off' },
  },
  {
    label: i18n.t('misc.new_writeoff'),
    route: { name: 'write-offs$create' },
  },
  {
    label: i18n.t('misc.write_off'),
    route: { name: 'write-offs$edit' },
  },
];

export default [
  {
    path: '/storage/write-offs',
    name: 'write-offs$write-offs',
    component: () => import('@/apps/write-offs/views/WriteOffs'),
    meta: {
      breadCrumb: breadCrumb.slice(0, 1),
      title: i18n.t('misc.warehouse_write_offs'),
    },
  },
  {
    path: '/storage/write-offs/:id',
    name: 'write-offs$write-off',
    component: () => import('@/apps/write-offs/views/Detail/Detail'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('misc.write_offs_write_off'),
    },
  },
  {
    path: '/storage/write-offs/create',
    name: 'write-offs$create',
    component: () => import('@/apps/write-offs/views/Create/Create'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2]],
      title: i18n.t('misc.create_write_off'),
    },
  },
  {
    path: '/storage/write-offs/:id/edit',
    name: 'write-offs$edit',
    component: () => import('@/apps/write-offs/views/Edit/Edit'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[3]],
      title: i18n.t('misc.edit_write_off'),
    },
  },
];
