// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

import { NoticeEnumTypes } from '@/core/data/Notice-enum';
import { companyAPI } from '@/core/api/company';

const state = {
    mainInfo: null,
    notice: null,
    deviceList: [],
};

const getters = {
    mainInfo: (state) => state.mainInfo,
    noticeInfo: (state) => state.noticeInfo,
    deviceList: (state) => state.deviceList,
};

const actions = {
    getMainInfo: async ({ commit, dispatch }, id) => {
        try {
            const response = await companyAPI.getStoreById({ id: id });
            commit('_getMainInfo', response);
        } catch (error) {
            dispatch(
                'core$notifications/add',
                {
                    message: error,
                    type: NoticeEnumTypes.error,
                    source: 'getMainInfo',
                    title: 'Error',
                },
                { root: true },
            );
        }
    },
    getNoticeInfo: async ({ commit, dispatch }, id) => {
        try {
            const response = await companyAPI.getBoxNoticeState(
                { store_id: id });
            commit('_getNoticeInfo', response);
        } catch (error) {
            dispatch(
                'core$notifications/add',
                {
                    message: error,
                    type: NoticeEnumTypes.error,
                    source: 'getNoticeInfo',
                    title: 'Error',
                },
                { root: true },
            );
        }
    },
    getDeviceList: async ({ commit, dispatch }, id) => {
        try {
            const response = await companyAPI.getDeviceList({ store_id: id });
            commit('_getDeviceList', response.items);
        } catch (error) {
            dispatch(
                'core$notifications/add',
                {
                    message: error,
                    type: NoticeEnumTypes.error,
                    source: 'getDeviceList',
                    title: 'Error',
                },
                { root: true },
            );
        }
    },
    updateStore: async ({ dispatch }, payload) => {
        try {
            await companyAPI.updateStore(payload);
            dispatch(
                'core$notifications/add',
                {
                    message: i18n.t('misc.changes_saved'),
                    type: NoticeEnumTypes.success,
                    source: '',
                    title: 'Success',
                },
                { root: true },
            );
        } catch (error) {
            dispatch(
                'core$notifications/add',
                {
                    message: error,
                    type: NoticeEnumTypes.error,
                    source: 'updateStore',
                    title: 'Error',
                },
                { root: true },
            );
        }
    },
    updateNotifications: async ({ dispatch }, payload) => {
        try {
            await companyAPI.setBoxNoticeState(payload);
            dispatch(
                'core$notifications/add',
                {
                    message: i18n.t('misc.changes_saved'),
                    type: NoticeEnumTypes.success,
                    source: '',
                    title: 'Success',
                },
                { root: true },
            );
        } catch (error) {
            dispatch(
                'core$notifications/add',
                {
                    message: error,
                    type: NoticeEnumTypes.error,
                    source: 'updateNotifications',
                    title: 'Error',
                },
                { root: true },
            );
        }
    },
    updateLogo: async ({}, payload) => {
        return await companyAPI.uploadStoreLogo(payload);
    },
};

const mutations = {
    _getMainInfo: (state, payload) => (state.mainInfo = payload),
    _getNoticeInfo: (state, payload) => (state.noticeInfo = payload),
    _getDeviceList: (state, payload) => (state.deviceList = payload),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
