import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-8e6199ce")
const _hoisted_1 = { class: "notification-item" }
const _hoisted_2 = { class: "item__header" }
const _hoisted_3 = { class: "item__header-title" }
const _hoisted_4 = { class: "item__body u-mt--1 u--ml--3" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createVNode(_component_Icon, {
        name: "icon_cross",
        onClick: _ctx.onClose,
        class: "item__header-close"
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.message), 1)
  ]))
}