// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('common.events'),
    route: { name: 'events$events' },
  },
  {
    label: '',
    route: { name: 'events$event' },
  },
];

export default [
  {
    path: '/events',
    name: 'events$events',
    component: () => import('@/apps/events/views/Events'),
    meta: {
      breadCrumb: [breadCrumb[0]],
      title: i18n.t('common.events'),
    },
  },
  {
    path: '/events/:id',
    name: 'events$event',
    component: () => import('@/apps/events/views/Detail/Detail'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
      title: i18n.t('misc.event_page'),
    },
  },
];
