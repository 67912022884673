// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

export default () => [
  {
    title: i18n.t('common.main_data'),
    isActive: true,
    completed: false,
    component: 'PrimaryData',
  },
  {
    title: i18n.t('common.trade_points'),
    isActive: false,
    completed: false,
    component: 'Tradepoints',
  },
  {
    title: i18n.t('common.categories'),
    isActive: false,
    completed: false,
    component: 'Categories',
  },
];
