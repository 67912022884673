
import { mapActions, mapGetters } from 'vuex';
import Preloader from '@/core/views/Preloader.vue';

export default {
  components: {
    Preloader,
  },

  computed: {
    ...mapGetters({
      loading: 'core$main/loading',
    }),
  },

  created() {
    this.handleStoreGlobalInit();
  },
  methods: {
    ...mapActions({
      globalInit: 'core$main/globalInit',
    }),
    handleStoreGlobalInit() {
      const route = this.$route;
      if (route.meta?.skipStoreGlobalInit) return;
      this.globalInit({ currentRoute: route.fullPath });
    },
  },
};
