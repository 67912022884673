import { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

const handleBPayExpiredTokenError = (error: AxiosError) => {
  if (
    error.response?.status === 401 &&
    window.navigator.userAgent.includes('B-Pay')
  ) {
    window.postMessage('tokenExpired', '*')
    // @ts-ignore
    window.ReactNativeWebView?.postMessage('tokenExpired');
    return true;
  }
  return false;
};

export const setupHttp = (http: AxiosInstance) => {
  http.interceptors.response.use((response: AxiosResponse) => response, (error: AxiosError) => {
    if (handleBPayExpiredTokenError(error)) return;
    return Promise.reject(error);
  });
};
