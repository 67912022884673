import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2e5fa467")
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  key: 0,
  class: "name"
}
const _hoisted_4 = {
  key: 1,
  class: "size"
}
_popScopeId()

import { computed } from 'vue';
import { MessageFile } from '@/core/components/Chat/types/MessageFile';
import { Icon } from '@brskl/ui-lib';
import { supportAPI } from '@/core/api/_support';
import { humanFileSize } from '@/core/utils/file';


export default _defineComponent({
  props: {
    file: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  file: MessageFile,
}) {

const props = __props


const nameLabel = computed(() => {
  const { file } = props;
  return file.name || `${file.id}.${file.file_extension}`;
});

const sizeLabel = computed(() => {
  const { file } = props;
  return file.size ? humanFileSize(file.size) : '';
});

const onClick = async (file: MessageFile) => {
  try {
    const result = await supportAPI.downloadFile({ id: file.id });
    window.open(result);
  } catch (error) {
    console.error(error);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "file-preview",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (onClick(__props.file)))
  }, [
    (__props.file.preview)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "preview",
          src: __props.file.preview,
          alt: _unref(nameLabel)
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createBlock(_unref(Icon), {
          key: 1,
          class: "preview-stub",
          name: "icon_attached-file-type"
        })),
    _createElementVNode("div", _hoisted_2, [
      (_unref(nameLabel))
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(nameLabel)), 1))
        : _createCommentVNode("", true),
      (_unref(sizeLabel))
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(sizeLabel)), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})