// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
    {
        label: i18n.t('misc.mailings'),
        route: { name: 'mailings$mailings' },
    },
];

export default [
    {
        path: '/mailings',
        name: 'mailings$mailings',
        component: () => import('@/apps/mailings/views/Index'),
        meta: {
            breadCrumb,
            title: i18n.t('misc.mailings'),
        },
    },
];
