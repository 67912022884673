<template>
  <div class="menu-part" v-if="profile">
    <div class="menu-item" v-for="(item, k) in filteredMenu.first" :key="k">
      <a class="menu-item__link" :href="item.route.external" v-if="!!item.route.external">
        <Icon v-if="item.icon" :name="item.icon" class="menu-item__icon" />
        <span class="menu-item__title">{{ item.title }}</span>
      </a>
      <router-link
        v-else
        :to="item.route"
        :class="['menu-item__link', { 'router-link-active': $route.name.split('$')[0] === item.route.name.split('$')[0] }]"
        @click="hideMenu"
      >
        <!-- dynamic class for active link in details same in children -->
        <Icon v-if="item.icon" :name="item.icon" class="menu-item__icon" />
        <span class="menu-item__title">{{ item.title }}</span>
      </router-link>
    </div>
  </div>
  <div class="menu-part">
    <div :class="['menu-item', { 'menu-item--children': item.children }]" v-for="(item, k) in filteredMenu.second" :key="k">
      <div :class="['menu-item__link', 'is-expandable', { 'is-expanded': showSubmenu === k }]" @click="toggleSubMenu(k)">
        <Icon v-if="item.icon" :name="item.icon" class="menu-item__icon" />
        <span class="menu-item__title">{{ item.title }}</span>
      </div>
      <div class="menu-item__submenu" v-if="item.children">
        <TransitionCollapse>
          <div v-show="showSubmenu === k" class="submenu">
            <template v-for="(subtitle, k) in item.children" :key="k">
              <a class="menu-item__subtitle" :href="subtitle.route.external" v-if="!!subtitle.route.external">{{ subtitle.title }}</a>
              <router-link
                v-else
                :to="subtitle.route"
                :class="[
                  'menu-item__subtitle',
                  { 'router-link-active': $route.name.split('$')[0] === subtitle.route.name.split('$')[0] },
                ]"
                @click="hideMenu"
              >
                {{ subtitle.title }}
              </router-link>
            </template>
          </div>
        </TransitionCollapse>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@brskl/ui-lib';
import TransitionCollapse from '@/core/components/TransitionCollapse/TransitionCollapse.vue';
import menu from '@/core/data/menu';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { hideShopFor, showPromoFor } from './data';

export default {
  components: { TransitionCollapse, Icon },
  emits: ['toggleMenu', 'openMenu'],
  props: {
    profile: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showSubmenu: false,
      menu,
      filteredMenu: {
        first: [],
        second: [],
      },
      second: [],
    };
  },
  computed: {
    ...mapGetters({
      isFeatureFlag: 'core$employeePreferences/isFeatureFlag',
      companyId: 'core$employeePreferences/companyId',
      isNewUser: 'main$main/isNewUser',
      company: 'core$company/company',
    }),
  },
  methods: {
    ...mapMutations({
      setSidebarCollapsed: 'core$menu/setSidebarCollapsed',
    }),
    ...mapActions({
      getEmployeePreferences: 'core$employeePreferences/getEmployeePreferences',
    }),
    toggleSubMenu(index) {
      if (this.showSubmenu === index) {
        this.showSubmenu = '';
      } else {
        this.showSubmenu = index;
        this.$emit('openMenu');
      }
    },
    hideMenu() {
      this.setSidebarCollapsed(false);
    },
    init() {
      if (this.profile.company.id !== 1 && this.profile.company.id !== 3146) {
        this.menu.splice(3, 1)
      }
      this.filteredMenu.first = this.menu.filter((el) => {
        return (!el.children && !this.isNewUser) || el?.route?.name === 'tradepoints$list';
      });

      this.second = this.menu.filter((el) => {
        return !!el.children && !this.isNewUser;
      });

      this.filteredMenu.second = this.second.map((el) => {
        if (!!el.children) {
          el.children = el.children.filter((route) => {
            route.title = this.$t(route.title);
            if (!!route.init_key) {
              // FIX: use country_code from company instead user profile company
              // this.profile.company.country_code === 'RU'
              return this.company?.country_code === 'RU' ? route.init_key === 'entities' : route.init_key === 'terminals';
            }
            return route;
          });
          return el;
        }
        return el;
      });

      this.toggleSubMenu(
        // Боже храни javascript
        this.filteredMenu.second.indexOf(
          this.filteredMenu.second.find((el) =>
            el.children.find((inner) => inner.route.name.split('$')[0] === this.$route.name.split('$')[0]),
          ),
        ),
      );
    },

    onMenuComponentUpdate() {
      this.init();
    },
  },
  watch: {
    isNewUser() {
      this.init();
    },
  },
  async created() {
    // fix for menu to get is_feature_flag
    try {
      await this.getEmployeePreferences();
    } catch (e) {
      console.error(e);
    }

    if (!this.isFeatureFlag) {
      this.menu = this.menu.filter((item) => item.key !== 'payments');
      // this.menu = this.menu.map((el) => {
      //   const _el = { ...el };
      //   if (_el.children && !showPromoFor.includes(this.companyId)) {
      //     _el.children = _el.children.filter((child) => child?.route?.name !== 'terminal-promo$terminal-promo');
      //   }
      //   return _el;
      // });

    }

    if (hideShopFor.includes(this.companyId)) this.menu = this.menu.filter((el) => el?.route?.name !== 'devices$buy');

    if (Object.keys(this.profile).length) this.init();
    this.$watch(
      () => this.profile,
      (value) => {
        if (Object.keys(value).length) this.init();
      },
    );
  },
};
</script>

<style src="./Menu.scss" lang="scss" scoped></style>
