// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('misc.stock'),
    route: { name: 'remains$remains' },
  },
];

export default [
  {
    path: '/storage/remains',
    name: 'remains$remains',
    component: () => import('@/apps/remains/Remains'),
    meta: {
      breadCrumb: breadCrumb.slice(0, 1),
      title: i18n.t('misc.warehouse_stock'),
    },
  },
];
