// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('misc.marketplace'),
    route: {name: 'marketplace'}
  },
]

export default [
  {
    path: '/marketplace',
    name: 'marketplace',
    component: () => import('@/apps/marketplace/views/List'),
    meta: {
      breadCrumb: [breadCrumb[0]],
      title: i18n.t('misc.marketplace'),
    },
  },
]
