export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "latest_cabinet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы находитесь в последней версии личного кабинета."])},
        "go_to_old_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к старой версии?"])},
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частые вопросы"])},
        "support_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Служба поддержки"])},
        "new_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое сообщение"])}
      },
      "en": {
        "latest_cabinet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are in the latest version of the personal account."])},
        "go_to_old_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the previous version?"])},
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
        "support_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support service"])},
        "new_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New message"])}
      }
    }
  })
}
