function humanFileSize(bytes,decimals) {
  if (bytes === 0) return '0 bytes';
  const k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export {
  humanFileSize,
};
