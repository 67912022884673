import { Icon } from '@brskl/ui-lib';

export const setupUILib = ({ manifest }) => {
  const spriteMapFileName = manifest?.['spritemap.svg']?.replace(/^\/|\/$/g, '');
  if (spriteMapFileName) {
    Icon.props.spriteFileName.default = spriteMapFileName;
  } else {
    console.warn('SVG sprite file name not found in manifest.json');
  }
};
