// --- INJECTED ---
import { getI18n } from '@/core/i18n';

const i18n = getI18n();
// --- /INJECTED ---

import { companyAPI } from '@/core/api/company';
import { produce } from 'immer';
import { cloneDeep, omitBy } from 'lodash';
import { NoticeEnumTypes } from '@/core/data/Notice-enum';

// TODO: добавить состояние для разных типов файловой интеграции

const initialState = () => ({
  catalogName: '',
  fileFields: null,
  columns: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  content: null,
  selected: {},
  selectedType: null,
  availableExchangeServiceTypes: [],
  activeService: null,
  serviceFields: {},
  serviceSettings: {},
  serviceErrors: {},
  serviceTests: {},
  serviceFiles: {},
  inputs: {
    category: '',
  },
});

const state = initialState();

const getters = {
  input: (state) => (name) => state.inputs[name],
  serviceStage: (state, getters) => (service) => {
    const serviceErrors = getters.serviceErrors(service);
    const serviceTests = getters.serviceTests(service);

    if (serviceTests) return STATUSES.SUCCESS;
    if (serviceErrors) return STATUSES.ERROR;

    return STATUSES.INIT;
  },
  catalogName: (state) => state.catalogName,
  canSkipStage: (state) => {
    if (Object.keys(state.serviceTests).length) return false;
    return true;
  },
  serviceFiles: (state) => (service) => state.serviceFiles[service],
  serviceSettings: (state) => (service) => state.serviceSettings[service],
  serviceErrors: (state) => (service) => state.serviceErrors[service],
  serviceFields: (state) => (service) => state.serviceFields[service],
  serviceTests: (state) => (service) => state.serviceTests[service],
  activeService: (state) => state.activeService,
  availableExchangeServiceTypes: (state) => state.availableExchangeServiceTypes,
  selectedType: (state) => state.selectedType,
  getServiceType: (state) => (service) => {
    switch (service) {
      case 'one_s':
      case 'iiko':
      case 'iiko_biz':
      case 'yml':
      case 'moy_sklad':
      case 'supermag':
      case 'mts_kassa':
      case 'store_house':
      case 'poster':
      case 'ya_badge':
        return 'accounting';

      case 'import_excel_file':
        return 'file';
    }
  },
  fileFields: (state) => state.fileFields && Object.keys(state.fileFields),
  columns: (state) => state.columns,
  content: (state) => state.content,
  flattenServiceFields: (state, getters) => (service) => {
    const target = getters.serviceFields(service);
    const fields = Object.values(target).
      flatMap((field) => Object.entries(field)).
      reduce((acc, [key, { value }]) => {
        return {
          ...acc,
          [key]: value,
        };
      }, {});
    return fields;
  },

  hasEmptyServiceRequiredFields: (state, getters) => (service) => {
    const { required } = getters.serviceFields(service);

    const values = Object.keys(required).map((k) => required[k]);

    return values.filter((v) => v).length !== Object.keys(required).length;
  },

  isFileFieldRequired: (state) => (name) => state.fileFields[name].required,
  selected: (state) => state.selected,
  columnLength: (state) => {
    if (!state.content) return 0;

    let len = 0;

    state.content.forEach((row) => {
      if (row.length > len) len = row.length;
    });

    return len;
  },

  localizeService: (state) => (name) => {
    switch (name) {
      case 'one_s':
        return 'service_one_s';

      case 'iiko':
        return 'service_iiko';

      case 'iiko_biz':
        return 'service_iiko_biz';

      case 'yml':
        return 'service_yml';

      case 'moy_sklad':
        return 'service_moy_sklad';

      case 'supermag':
        return 'service_supermag';

      case 'xlsx':
        return 'service_xlsx';

      case 'mts_kassa':
        return 'service_mts_kassa';

      case 'store_house':
        return 'service_store_house';

      case 'poster':
        return 'service_poster';

      case 'ya_badge':
        return 'service_ya_badge';

      default:
        return name;
    }
  },

  localizeFileField: (state) => (name) => {
    switch (name) {
      case 'article':
        return 'article';

      case 'name':
        return 'name';

      case 'price':
        return 'price';

      case 'vat_rate':
        return 'vat_rate';

      case 'barcode':
        return 'barcode';

      case 'unit_id':
        return 'unit_id';

      case 'unit_dimension':
        return 'volume';

      case 'quantity':
        return 'quantity';

      case 'category':
        return 'category';
    }
  },
};

const actions = {
  setCatalogName: ({ commit }, value) => {
    commit('_setCatalogName', value);
  },

  selectActiveService: ({ commit }, value) => {
    commit('_setActiveService', value);
  },

  createServiceExchange: async ({ commit, getters, rootGetters, dispatch }, { service, store }) => {
    if (getters.catalogName === '') {
      const item = {
        message: {
          error: {
            message: i18n.t('misc.enter_catalog_name'),
          },
        },
      };

      dispatch(
        'core$notifications/add',
        {
          message: item.message,
          type: NoticeEnumTypes.error,
          source: 'createServiceExchange',
          title: 'Error',
        },
        { root: true },
      );
      return;
    }

    if (getters.hasEmptyServiceRequiredFields(service)) return;

    try {
      const catalog = await companyAPI.createCatalog({
        name: getters.catalogName,
        currency_code: 'RUB',
      });

      const payload = {
        ...getters.flattenServiceFields(service),
        external_service: getters.activeService,
        catalog_id: catalog.id,
      };

      let serviceSettings = getters.serviceSettings(service);

      if (serviceSettings) {
        payload.id = serviceSettings.id;
      }

      // This code down below was fixed by a Back-ender. From here...
      try {
        const freshServiceSettings = serviceSettings
          ? await companyAPI.updateExchangeSettings(payload)
          : await companyAPI.createExchangeSettings(payload);
        commit('_setServiceSettings', { service, value: freshServiceSettings });
        serviceSettings = getters.serviceSettings(service);

        await companyAPI.bindCatalogToStore({ id: store.id, catalog_id: catalog.id });

        dispatch(
          'core$notifications/add',
          {
            message: i18n.t('common.creation_successful'),
            type: NoticeEnumTypes.success,
            source: '',
            title: 'Success',
          },
          { root: true },
        );
      } catch (exception) {
        dispatch(
          'core$notifications/add',
          {
            message: exception.error.message,
            type: NoticeEnumTypes.error,
            source: 'createTradePoint',
            title: 'Error',
          },
          { root: true },
        );
      }
      // ...to here!!!
    } catch (error) {
      console.log(error);
      /*  dispatch(
        'core$notifications/add',
        {
          message: error?.error?.message,
          type: NoticeEnumTypes.error,
          source: 'createTradePoint',
          title: 'Error',
        },
        { root: true },
      ); */
    }
  },

  setServiceField: ({ commit }, { service, type, field, value }) => {
    commit('_setServiceField', { service, type, field, value });
  },

  setActiveService({ commit }, service) {
    commit('_setActiveService', service);
  },

  fetchServiceFields: async ({ commit, getters }, service) => {
    if (getters.serviceFields[service]) return;

    try {
      const fields = await companyAPI.getExchangeServiceFields({ service });
      commit('_setServiceFields', { service, fields });
    } catch (error) {
      console.error(error);
    }
  },

  fetchAvailableExchangeServiceTypes: async ({ commit, getters }) => {
    if (getters.availableExchangeServiceTypes.length) return;

    try {
      const services = await companyAPI.getExchangeAvailableServices();
      commit('_setAvailableExchangeServiceTypes', services);
    } catch (error) {
      console.error(error);
    }
  },

  loadFileFields: async ({ commit, getters }) => {
    if (getters.fileFields) return;

    try {
      const result = await companyAPI.getExchangeFileFields();
      commit('_setFileFields', result);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  _setCatalogName: (state, value) => {
    state.catalogName = value;
  },

  _setServiceSettings: (state, { service, value }) => {
    state.serviceSettings = produce(cloneDeep(state.serviceSettings), (draft) => {
      draft[service] = value;
    });
  },

  _setAvailableExchangeServiceTypes: (state, value) => {
    state.availableExchangeServiceTypes = value;
  },

  _setServiceFields: (state, { service, fields }) => {
    if (state.serviceFields[service]) return;

    state.serviceFields = produce(cloneDeep(state.serviceFields), (draft) => {
      let { preset, optional, required } = fields;

      const usedFields = { ...optional, ...required };
      preset = omitBy(preset, (_, key) => {
        return usedFields.hasOwnProperty(key);
      });

      draft[service] = { required, optional, preset };
    });
  },

  _setServiceFiles: (state, { service, value }) => {
    state.serviceFiles = produce(cloneDeep(state.serviceFiles), (draft) => {
      draft[service] = value;
    });
  },

  _setServiceField: (state, { service, type, field, value }) => {
    state.serviceFields = produce(cloneDeep(state.serviceFields), (draft) => {
      draft[service][type][field].value = value;
    });
  },

  _setFileFields: (state, value) => {
    state.fileFields = value;
  },

  _setActiveService: (state, value) => {
    state.activeService = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
