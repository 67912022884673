export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "error_try_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что то пошло не так, попробуйте перезагрузить страницу"])}
      },
      "en": {
        "error_try_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please, try to reload page"])}
      }
    }
  })
}
