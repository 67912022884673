// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('misc.operations'),
    route: { name: 'operations$list' },
  },
];

export default [
  {
    path: '/operations',
    name: 'operations$list',
    component: () => import('@/apps/operations/views/List/List.vue'),
    meta: {
      breadCrumb: [breadCrumb[0]],
    },
  },
  // {
  //   path: '/operations/:uuid',
  //   name: 'operations$operation',
  //   component: () => import('@/apps/operations/views/Operation/Operation.vue'),
  //   meta: {
  //     breadCrumb: [breadCrumb[0], breadCrumb[1]],
  //   },
  // },
];
