// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('misc.profile_settings'),
    route: { name: 'profile$profile' },
  },
];

export default [
  {
    path: '/profile',
    name: 'profile$profile',
    component: () => import('@/apps/profile/views/Profile'),
    meta: {
      breadCrumb: breadCrumb,
      title: i18n.t('misc.user_profile')
    },
  }
]
