import { ordersAPI } from '@/apps/orders/api';
import { cloneDeep } from 'lodash';
// import { getI18n } from '@/core/i18n';
import { getI18n } from '@/core/i18n';
import { NoticeEnumTypes } from '@/core/data/Notice-enum';

const i18n = getI18n();

const state = {
  loading: true,
  statusDictionary: {},
  order: {},
  orderBackup: {},
  refunds: [],
  savedFilters: '',
  search: '',
};

const getters = {
  statusDictionary: (state) => state.statusDictionary,
  order: (state) => state.order,
  refunds: (state) => state.refunds,
  loading: (state) => state.loading,
  savedFilters: (state) => state.savedFilters,
  search: (state) => state.search
};

const actions = {
  setSearchValue({ commit }, value) {
    commit('_setSearch', value)
  },
  getStatusesDictionary: async ({ commit }) => {
    try {
      const list = await ordersAPI.getStatusesDictionary();
      commit('_setStatusesDictionary', list);
    } catch (error) {
      console.log(error);
    }
  },
  getOrderById: async ({ commit }, id) => {
    commit('_clearState');
    try {
      const order = await ordersAPI.getOrderById({ id });
      commit('_setOrder', order);
    } catch (error) {
      console.error(error);
    }
  },
  getRefunds: async ({ commit, state }) => {
    const refunds = state.order.child_orders;
    if (!refunds || !refunds.length) return;
    refunds.forEach(async (id) => {
      try {
        const data = await ordersAPI.getOrderById({ id });
        commit('_addRefund', data);
      } catch (error) {
        console.log(error);
      }
    });
  },
  executeRefunds: async ({ commit, dispatch }, { parent_order_id, items }) => {
    try {
      const response_refund = await ordersAPI.createRefund({ parent_order_id, items, mode: 0 });
      if (response_refund.error_log.length) {
        dispatch(
          'core$notifications/add',
          {
            message: { error: { message: response_refund.error_log.join('\n') } },
            type: NoticeEnumTypes.error,
            source: 'executeRefunds',
            title: i18n.t('common.error'),
          },
          { root: true },
        );
      }
    } catch (error) {
      dispatch(
        'core$notifications/add',
        {
          message: { error: { message: error.error_log.join('\n') } },
          type: NoticeEnumTypes.error,
          source: 'executeRefunds',
          title: i18n.t('common.error'),
        },
        { root: true },
      );
    }
    const {
      data: { order_id, pay_u_order_id },
    } = await ordersAPI.createRefund({ parent_order_id, items, mode: 1 });
    await ordersAPI.acceptRefund({ order_id, pay_u_order_id, mode: 0 });
    try {
      const report = await ordersAPI.acceptRefund({ order_id, pay_u_order_id, mode: 1 });
      if (report.error_log.length) {
        throw { error: { message: report.error_log.join('\n') } };
      }
      dispatch(
        'core$notifications/add',
        {
          message: i18n.t('misc.return_completed'),
          type: NoticeEnumTypes.success,
          source: 'executeRefunds',
          title: i18n.t('common.success'),
        },
        { root: true },
      );
    } catch (error) {
      dispatch(
        'core$notifications/add',
        {
          message: error,
          type: NoticeEnumTypes.error,
          source: 'executeRefunds',
          title: i18n.t('common.error'),
        },
        { root: true },
      );
    }
  },
};

const mutations = {
  _setStatusesDictionary: (state, dictionary) => {
    state.statusDictionary = { ...dictionary };
  },
  _setOrder: (state, order) => {
    state.order = order;
    state.orderBackup = cloneDeep(order);
    state.loading = false;
  },
  _addRefund: (state, refund) => {
    state.refunds.push(refund);
  },
  _clearState: (state) => {
    state.refunds = [];
    state.loading = true;
  },
  _resetOrder: (state) => {
    state.order = cloneDeep(state.orderBackup);
  },
  _saveFilters: (state, filters) => {
    state.savedFilters = filters;
  },
  _setSearch: (state, value) => {
    state.search = value
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
