// --- INJECTED ---
import {getI18n} from '@/core/i18n';

const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('common.service_center'),
  },
  {
    label: i18n.t('misc.counterparties'),
    route: {name: 'service-contractors$list'},
  },
  {
    label: i18n.t('misc.add_counterparty'),
    route: {name: 'service-contractors$create'},
  },
  {
    label: i18n.t('misc.create_counterparty'),
    route: {name: 'service-contractors$new'},
  },
];

export default [
  {
    path: '/service-center/contractors',
    name: 'service-contractors$list',
    component: () => import('@/apps/service_center/contractors/views/List/List.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
    },
  },
  {
    path: '/service-center/contractors/create',
    name: 'service-contractors$create',
    component: () => import('@/apps/service_center/contractors/views/Create/Create.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[2]],
    },
  },
  {
    path: '/service-center/contractors/new',
    name: 'service-contractors$new',
    component: () => import('@/apps/service_center/contractors/views/CreateNew/CreateNew.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[3]],
    },
  },
];
