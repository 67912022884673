// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

import { NoticeEnumTypes } from '@/core/data/Notice-enum';
import { companyAPI } from '@/core/api/company';

const state = {
  storeInfo: null,
  storeState: null,
  partyInfo: null,
  partyInfoById: null,
  storeDevices: [],
  externalID: '',
};

const getters = {
  storeInfo: (state) => state.storeInfo,
  storeState: (state) => state.storeState,
  partyInfo: (state) => state.partyInfo,
  partyInfoById: (state) => state.partyInfoById,
  storeDevices: (state) => state.storeDevices,
  storeExchange: (state) => state.storeExchange,
  storeExchange: (state) => state.storeExchange,
  externalID: (state) => state.externalID,
};

const actions = {
  resetState({ state }) {
    (state.storeInfo = null), (state.storeState = null), (state.partyInfo = null), (state.storeDevices = []);
  },
  getStoreExchange: async ({ commit, dispatch }, store_id) => {
    try {
      const storeExchange = await companyAPI.getExchangeSettingsName({ store_id: store_id });
      commit('_getStoreExchange', storeExchange.exchange_settings_name);
    } catch (error) {
      console.error(error);
      // dispatch(
      //   'core$notifications/add',
      //   { message: error, type: NoticeEnumTypes.error, source: 'getStoreExchange', title: 'Error' },
      //   { root: true },
      // );
    }
  },
  getStoreInfo: async ({ commit, dispatch }, store_id) => {
    try {
      let tradePoint = await companyAPI.getStoreById({ id: store_id });

      if (tradePoint.extended_type === 'coffee_machine') {
        const { info } = await companyAPI.getCoffeeMachineSerialNumber({ id: tradePoint.id });

        if (info.kind === 'wmf') {
          try {
            const { cup } = await companyAPI.getCoffeeMachineCup({ store_id: tradePoint.id });
            tradePoint.isCupEnabled = cup.enabled;
            tradePoint.cupPrice = cup.price;
          } catch (error) {
            console.error(error);
          }
        }

        tradePoint.serial_number = info.serial_number;
        tradePoint.is_menu_available = info.is_menu_available;
        tradePoint.type_of_coffee_machine = info.kind;
      }

      commit('_getStoreInfo', tradePoint);
    } catch (error) {
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'getStoreInfo', title: 'Error' },
        { root: true },
      );
    }
  },
  setExternalID: async ({ commit, dispatch }, { store_id, ext_id }) => {
    try {
      const { external_id } = await companyAPI.updateStore({ id: store_id, external_id: ext_id });

      commit('_setExternalID', external_id);
    } catch (error) {
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'getStoreInfo', title: 'Error' },
        { root: true },
      );
    }
  },

  getPartyInfo: async ({ commit, dispatch, state }, party_id) => {
    if (state.storeInfo.party_id === 0) return;
    try {
      // const partyInfo = await companyAPI.getPartyById({ id: party_id });
      let { items } = await companyAPI.getExchangePartyList({
        fields: {
          id: 'id',
          name_short_with_opf: 'name_short_with_opf',
          activation_status: 'activation_status',
          is_completed: 'is_completed',
        },
        limit: 1,
        filters: { id: party_id },
      });
      commit('_getPartyInfo', items[0]);
    } catch (error) {
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'getPartyInfo', title: 'Error' },
        { root: true },
      );
    }
  },
  getPartyInfoById: async ({ commit, dispatch, state }, party_id) => {
    if (state.storeInfo.party_id === 0) return;
    try {
      const partyInfo = await companyAPI.getPartyById({ id: party_id });
      commit('_getPartyInfoById', partyInfo);
    } catch (error) {
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'getPartyInfoById', title: 'Error' },
        { root: true },
      );
    }
  },
  toggleTradePointStatus: async ({ context, dispatch }, store_id) => {
    try {
      return await companyAPI.activateStore({ id: store_id }).then(() => {
        context.dispatch('getStoreInfo', store_id);
      });
    } catch (error) {
      Object.entries(error).forEach((err) => {
        let title = i18n.t('misc.activation_error');
        if (err[0] === 'employee') title = i18n.t('misc.no_employees');
        if (err[0] === 'items') title = i18n.t('misc.no_catalog');
        if (err[0] === 'schedule') title = i18n.t('misc.no_schedule');
        dispatch(
          'core$notifications/add',
          {
            message: {
              error: { message: err[1] },
            },
            type: NoticeEnumTypes.error,
            source: 'toggleTradePointStatus',
            title,
          },
          { root: true },
        );
      });
    }
  },
  getStoreState: async ({ commit, dispatch }, store_id) => {
    try {
      const storeState = await companyAPI.getStoreBoxState({ id: store_id });
      commit('_getStoreState', storeState);
    } catch (error) {
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'getStoreState', title: 'Error' },
        { root: true },
      );
    }
  },
  getStoreStatesByIds: async ({ commit, dispatch }, stores_ids) => {
    try {
      const storesState = await companyAPI.getStoresStatesByIds({ stores_ids: stores_ids });
      commit('_getStoreStatesByIds', storesState);
      return storesState;
    } catch (error) {
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'getStoreStatesByIds', title: 'Error' },
        { root: true },
      );
    }
  },
  getStoreDevices: async ({ commit, dispatch }, store_id) => {
    try {
      const response = await companyAPI.getDeviceList({ store_id: store_id });
      commit('_getStoreDevices', response.items);
    } catch (error) {
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'getStoreDevices', title: 'Error' },
        { root: true },
      );
    }
  },
};

const mutations = {
  _getStoreInfo: (state, payload) => (state.storeInfo = payload),
  _getStoreState: (state, payload) => (state.storeState = payload),
  _getStoreStatesByIds: (state, payload) => (state.storeStatesByIds = payload),
  _getPartyInfo: (state, payload) => (state.partyInfo = payload),
  _getPartyInfoById: (state, payload) => (state.partyInfoById = payload),
  _getStoreDevices: (state, payload) => (state.storeDevices = payload),
  _getStoreExchange: (state, payload) => (state.storeExchange = payload),
  _setExternalID: (state, payload) => console.log(payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
