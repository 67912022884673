// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

import { companyAPI } from '@/core/api/company';

const state = {
  storeCatalogs: [],
  storeCategories: [],
  search: '',
};

const getters = {
  catalogs: (state) => state.storeCatalogs,
  categories: (state) => state.storeCategories,
  catalogsForItem: (state) => state.storeCatalogs.slice(1),
  search: (state) => state.search,
};

const actions = {
  setSearchValue({ commit }, value) {
    commit('setSearch', value)
  },
  setDiscountFieldsList: async ({ commit }) => {
    try {
      const list = await companyAPI.getItemDiscountFields();
      commit('_setDiscountFieldsList', list);
    } catch (error) {
      console.error('fetchCatalogs error:', error);
    }
  },

  fetchCatalogs: async ({ commit }, params = { page: 1, limit: 200 }) => {
    try {
      const { items } = await companyAPI.getCatalogListV1(params);
      commit('setCatalogs', { items });
    } catch (error) {
      console.error('fetchCatalogs error:', error);
    }
  },

  fetchCategories: async ({ commit }, params = { page: 1, limit: 200 }) => {
    try {
      const { items } = await companyAPI.getCategoryListV1(params);
      commit('setCatalogs', { items });
    } catch (error) {
      console.error('fetchCategories error:', error);
    }
  },
};

const mutations = {
  setSearch: (state, value) => {
    state.search = value;
  },
  setCatalogs: (state, { items }) => {
    // const i18n = getI18n();
    // state.storeCatalogs = [{ id: 0, name: i18n.t('all') }].concat(items);
    state.storeCatalogs = items;
    // state.storeCatalogs = [{ id: 0, name: i18n.t('common.all') }].concat(items);
  },
  setCategories: (state, { items }) => {
    state.storeCategories = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
