
import { Button, Icon } from '@brskl/ui-lib';
import { NoticeEnumTypes } from '@/core/data/Notice-enum';
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['close'],

  components: {
    //@ts-ignore
    Icon,
    //@ts-ignore
    Button,
  },
  //@ts-ignore
  computed: {
    type() {
      return this.item?.type || NoticeEnumTypes.default;
    },

    title() {
      return this.item?.title || '';
    },

    message() {
      if (this.type === 'error') {
        return !!this.item.message.error
          ? this.$t(this.item?.message.error.message)
          : this.$t('error_try_reload') || '';
      } else {
        return this.item?.message || '';
      }
    },
  },

  props: {
    item: {
      type: Object,
    },
  },

  methods: {
    onClose() {
      this.$emit('close', this.item.id);
    },
    autoClose() {
      setTimeout(this.onClose, 7000);
    },
  },

  mounted() {
    this.autoClose();
  },
});
