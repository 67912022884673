// --- INJECTED ---
import { getI18n } from '@/core/i18n';

const i18n = getI18n();
// --- /INJECTED ---

import { companyAPI } from '@/core/api/company';
import { format } from 'date-fns';
import { NoticeEnumTypes } from '@/core/data/Notice-enum';

import router from '@/core/router';

const state = {
  period: {
    start: '',
    end: '',
  },
  selectedTradepoints: [],
  selectedItems: [],
  report: null,
  tabVisible: 'period',
  shouldFetchItemsTable: false,
  loading: false,
};

const getters = {
  period: (state) => state.period,
  selectedTradepoints: (state) => state.selectedTradepoints,
  selectedItems: (state) => state.selectedItems,
  report: (state) => state.report,
  tabVisible: (state) => state.tabVisible,
  shouldFetchItemsTable: (state) => state.shouldFetchItemsTable,
  loading: (state) => state.loading,
};

const actions = {
  changeTab: async ({ commit, dispatch, state }, type) => {
    if (type === 'items') {
      await dispatch('setFetchItemsTable', true);
    } else {
      await dispatch('setFetchItemsTable', false);
    }
    commit('_changeTab', type);
  },

  setPeriod: ({ commit }, period) => {
    commit('_setPeriod', period);
  },

  setSelectedTradepoints: ({ commit }, tradepoints) => {
    commit('_setSelectedTradepoints', tradepoints);
  },

  setFetchItemsTable: ({ commit }, flag) => {
    commit('_setFetchItemsTable', flag);
  },

  setSelectedItems: ({ commit }, items) => {
    commit('_setSelectedItems', items);
  },

  setDateFormat: ({ state }) => {
    //YYYY-mm-dd HH:ii:ss
    const dateS = new Date(state.period.start);
    const dateE = new Date(state.period.end);
    const dateStart = format(dateS, 'yyyy-MM-dd') + ' 00:00:00';
    const dateEnd = format(dateE, 'yyyy-MM-dd') + ' 23:59:59';

    return { dateStart, dateEnd };
  },

  getReport: async ({ commit }, id) => {
    const { items, params } = await companyAPI.getStorageReportByID(id);
    commit('_setReport', params);
  },

  setLoading: ({ commit }, loading) => {
    commit('_setLoading', loading);
  },

  updateReport: async ({ commit, state, dispatch }, report_id) => {
    await dispatch('setLoading', true);

    const period = await dispatch('setDateFormat');

    const store_id = state.selectedTradepoints.map((tradepoint) => tradepoint.id);
    const item_id = state.selectedItems.map((item) => item.id);

    const params = {
      fields: {
        item_id: 'item_movement_position.item_id',
        unit_name: 'unit.name',
        store_id: 'item_movement.store_id',
        store_name: 'store.name',
        item_name: 'item_movement_position.item_name',
        total_demand: 'total_demand',
        total_supply: 'total_supply',
        item_amount: 'item_amount',
        timestamp_inserting: 'timestamp_inserting',
        item_barcode: 'item_movement_position.item_barcode',
        item_stock_end_report_period: 'item_stock_end_report_period',
        item_stock_start_report_period: 'item_stock_start_report_period',
      },
      filters: {
        report_start_date: period.dateStart,
        report_till_date: period.dateEnd,
        store_id,
        item_id,
      },
      grouping: ['item_movement.store_id', 'item_movement_position.item_id'],
    };

    try {
      const { items, meta } = await companyAPI.createStorageReport(params);

      const response = await companyAPI.deleteStorageReport({ id: report_id });

      const response_name = await companyAPI.setReportName(
        { id: meta.report_id, name: state.report.name });

      await dispatch('setLoading', false);

      /* line 117 */
      dispatch(
        'core$notifications/add',
        {
          message: i18n.t('misc.update_success'),
          type: NoticeEnumTypes.success,
          source: 'updateReport',
          title: i18n.t('common.success'),
        },
        { root: true },
      );

      router.push({ name: 'storage-reports$detail', params: { id: meta.report_id } });

      commit('_setNewReportTableData', items);
      commit('_setNewReportMeta', meta);
      commit('_setNewReportID', meta.report_id);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  _setLoading: (state, payload) => (state.loading = payload),
  _changeTab: (state, payload) => (state.tabVisible = payload),
  _setPeriod: (state, payload) => (state.period = payload),
  _setSelectedTradepoints: (state, payload) => (state.selectedTradepoints = payload),
  _setSelectedItems: (state, payload) => (state.selectedItems = payload),
  _setFetchItemsTable: (state, payload) => (state.shouldFetchItemsTable = payload),
  _setReport: (state, payload) => (state.report = payload),
  _setNewReportMeta: (state, payload) => (state.newReportMeta = payload),
  _setNewReportTableData: (state, payload) => (state.newReportTableData = payload),
  _setNewReportID: (state, payload) => (state.newReportID = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
