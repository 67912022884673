<template>
  <div class="notifications">
    <div v-show="items.length > 2" class="closeButton">
      <div @click="onCloseAll" class="notifications__hide-all">{{ $t('hide_notifications') }}</div>
    </div>
    <component v-for="item in items" :key="item.id" :is="computeMessageComponent(item.type)" :item="item" @close="onClose" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Button } from '@brskl/ui-lib';
import RegularNotification from '@/core/components/organism/OnlineCartNotifications/RegularNotification/RegularNotification.vue';

export default {
  components: {
    RegularNotification,
    Button,
  },

  data() {
    return {
      caitems: [
        { id: 1, message: this.$t('common.save_completed_success'), source: 'onUpdateHandler', title: 'Успех', type: 'success' },
        { id: 2, message: this.$t('common.save_completed_success'), source: 'onUpdateHandler', title: 'Успех', type: 'success' },
        { id: 3, message: this.$t('common.save_completed_success'), source: 'onUpdateHandler', title: 'Успех', type: 'success' },
        { id: 4, message: this.$t('common.save_completed_success'), source: 'onUpdateHandler', title: 'Успех', type: 'success' },
      ],
    };
  },

  computed: {
    ...mapGetters({ items: 'core$notifications/items' }),
  },

  methods: {
    ...mapActions({
      remove: 'core$notifications/remove',
      removeAll: 'core$notifications/removeAll',
    }),

    computeMessageComponent() {
      // RegularNotification - системные сообщения
      return 'RegularNotification';
    },

    onClose(id) {
      this.remove(id);
    },

    onCloseAll() {
      this.items = [];
      this.removeAll();
    },
  },
};
</script>

<style src="./Notifications_1.scss" lang="scss" scoped></style>

<i18n>
{
  "ru": {
    "hide_notifications": "Скрыть все уведомления"
  },
  "en": {
    "hide_notifications": "Hide all notifications"
  }
}
</i18n>
