// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

import { NoticeEnumTypes } from '@/core/data/Notice-enum';
import { companyAPI } from '@/core/api/company';
import router from '@/core/router';

const state = {
  loadedFieldsToComplete: null
};

const getters = {
  loadedFieldsToComplete: (state) => state.loadedFieldsToComplete,
};

const actions = {
  getPartyFieldsToComplete: async ({ commit }, payload) => {
    try {
      const fields = await companyAPI.getFieldsToComplete({ party_id: payload });
      commit('_getPartyFieldsToComplete', fields);
    } catch (error) {
      console.error('store/getPartyFieldsToComplete:', error);
    }
  },
  completeRefill: async ({ dispatch }, payload) => {
    try {
      if (Object.keys(payload.fields.party).length) {
        await companyAPI.patchParty({
          id: payload.ids.party,
          ...Object.fromEntries(Object.entries(payload.fields.party).filter(([_, v]) => v != ''))
        })
      }
      dispatch(
        'core$notifications/add',
        { message: i18n.t('common.changes_saved_ok'), type: NoticeEnumTypes.success, source: '', title: 'Success' },
        { root: true },
      );
      router.push({ name: 'terminals$detail', params: { id: payload.ids.party } });
    } catch (error) {
      dispatch(
        'core$notifications/add',
        { message: error, type: NoticeEnumTypes.error, source: 'completeRefill', title: 'Error' },
        { root: true },
      );
    }
  },
};

const mutations = {
  _getPartyFieldsToComplete: (state, payload) => (state.loadedFieldsToComplete = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
