// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
    {
        label: i18n.t('misc.compensations'),
        route: { name: 'refunds$list' },
    },
];

export default [
    {
        path: '/refunds',
        name: 'refunds$list',
        component: () => import('@/apps/refunds/views/List/List.vue'),
        meta: {
            breadCrumb: [breadCrumb[0]],
        },
    },
];
