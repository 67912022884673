// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('common.documents'),
    route: { name: 'documents$acts' },
  },
];

export default [
  {
    path: "/documents",
    name: "documents$documents",
    component: () => import("@/apps/documents/views/Documents"),
    redirect: { name: "documents$acts" },
    meta: {
      title: i18n.t('common.documents'),
    },
    children: [
      {
        path: "acts",
        name: "documents$acts",
        component: () => import("@/apps/documents/views/Acts/Acts"),
        meta: {
          breadCrumb,
          title: i18n.t('misc.acts'),
        },
      },
      {
        path: 'bills',
        name: 'documents$bills',
        component: () => import('@/apps/documents/views/Bills/Bills'),
        meta: {
          breadCrumb,
          title: i18n.t('misc.accounts'),
        },
      },
      {
        path: "docs",
        name: "documents$docs",
        component: () => import("@/apps/documents/views/Docs/Docs"),
        meta: {
          breadCrumb,
          title: i18n.t('common.documents'),
        },
      },

    ]
  },
  {
    path: "docs/sign/:doc_id",
    name: "documents$sign-docs",
    component: () => import("@/apps/documents/views/Docs/DocumentSign/DocumentSign"),
    meta: {
      breadCrumb,
      title: i18n.t('misc.sign_document'),
    },
    props: true,
  },
];
