interface ReactNativeWebView {
  postMessage: (message: string) => void;
}

interface WindowReactNativeWebView extends Window {
  ReactNativeWebView?: ReactNativeWebView;
}

// eslint-disable-next-line import/prefer-default-export
export const postExternalWebViewMessage = (message: string) => {
  const reactNativeWebView = (window as WindowReactNativeWebView).ReactNativeWebView;
  reactNativeWebView?.postMessage(message);
};
