// --- INJECTED ---
import {getI18n} from '@/core/i18n';

const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('common.service_center'),
  },
  {
    label: i18n.t('misc.diagnostics'),
    route: {name: 'service-contractors$diagnostics'},
  },
];

export default [
  {
    path: '/service-center/diagnostics',
    name: 'service-diagnostics$diagnostics',
    component: () => import('@/apps/service_center/diagnostics/views/Diagnostics/Diagnostics.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
    },
  },
];
