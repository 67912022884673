export default {
  common: {
    online_carts: 'Онлайн-корзины',
    settings: 'Настройки',
    trade_point: 'Торговая точка',
    trade_points: 'Торговые точки',
    category: 'Категория',
    catalog: 'Каталог',
    catalogs: 'Каталоги',
    categories: 'Категории',
    discounts: 'Скидки',
    documents: 'Документы',
    employees: 'Сотрудники',
    add_employee: 'Добавить сотрудника',
    employee_page: 'Страница сотрудника',
    legal_entities: 'Юридические лица',
    events: 'События',
    goods: 'Товары',
    print_sticker_tag: 'Печать стикера и ценника',
    print_sticker: 'Печать стикера',
    menu: 'Меню',
    payment: 'Оплата',
    video: 'Видео',
    promo_codes: 'Промокоды',
    delivery: 'поставку',
    virtual_terminals: 'Виртуальные терминалы',
    details: 'Реквизиты',
    address: 'Адрес',
    administrator: 'Администратор',
    courier: 'Курьер',
    checker: 'Чек-ер',
    not_installed: 'Не установлен',
    error: 'Ошибка!',
    success: 'Успех',
    proceed: 'Перейти',
    photo: 'Фото',
    barcode: 'Штрихкод',
    name: 'Имя',
    price: 'Цена',
    quantity: 'Кол-во',
    discount: 'Скидка',
    total_amount: 'Итоговая сумма',
    order: 'Заказ',
    add_discount: 'Добавить скидку',
    month: 'месяц',
    half_year: 'Полгода',
    year: 'год',
    six_months: 'полгода',
    type: 'Тип',
    phone: 'Телефон',
    role: 'Роль',
    delete_employee: 'Удалить сотрудника',
    attached_trade_points: 'Прикреплённые торговые точки',
    cancel: 'Отменить',
    confirm: 'Подтвердить',
    data_not_found: 'Данные с выбранными параметрами не найдены',
    change_filter_set: 'Измените настройки фильтра',
    delete_success: 'Успешно удалено',
    changes_saved_ok: 'Изменения успешно сохранены',
    average: 'Средняя',
    save: 'Сохранить',
    time: 'Время',
    good: 'Товар',
    total: 'Итог',
    payment_number: 'Оплата №',
    trade_point_id: 'ID торговой точки',
    client_id: 'ID клиента',
    creation_time: 'Время создания',
    amount: 'Сумма',
    status: 'Статус',
    period: 'Период',
    order_status: 'Статус заказа',
    measurement: 'Измерение',
    active: 'Активный',
    inactive: 'Неактивный',
    sticker: 'Стикер',
    good_id: 'ID товара',
    yes: 'да',
    no: 'нет',
    catalog_name: 'Название каталога',
    category_name: 'Название категории',
    trade_point_id_name: 'ID или название торговой точки',
    all: 'Все',
    connected: 'подключен',
    disabled: 'Выключено',
    delete_good: 'Удалить товар',
    export_good: 'Экспортировать товар',
    move_to_category: 'Перенести в категорию',
    remove_from_category: 'Убрать из категории',
    filters: 'Фильтры',
    table_setup: 'Настройка таблицы',
    confirm_delete_good: 'Вы действительно хотите удалить товар?',
    download_csv: 'Скачать .csv',
    download_xlsx: 'Скачать .xlsx',
    goods_list_empty: 'Список ваших товаров пока пуст.',
    you_can: 'Вы можете',
    add_good: 'Добавить товар',
    choose_period: 'выбрать период',
    choose_date: 'выбрать дату',
    base_catalog: 'Базовый каталог',
    clear_filters: 'Очистить фильтры',
    creation_date: 'Дата создания',
    micro_market_rating: 'Рейтинг микромаркетов',
    last_name: 'Фамилия',
    time_zone: 'Часовой пояс',
    undefined: 'Все дни',
    availability: 'Наличие',
    product_name: 'Название товара',
    base_price: 'Базовая цена',
    trade_point_name: 'Название торговой точки',
    reporting_period: 'Отчетный период',
    group_by: 'Группировать по',
    no_data_parameters: 'Нет данных по указанным параметрам',
    pieces: 'шт.',
    orders: 'Заказы',
    unknown: 'Неизвестно',
    main: 'Основной',
    deliveries: 'Доставки',
    service_center: 'Сервисный центр',
    equipment: 'Оборудование',
    delivery_date_time: 'Дата и время поставки',
    goods_quantity: 'Кол-во товаров',
    supply_status: 'Статус поставки',
    operation_method: 'Способ операции',
    supply_number: 'Поставка №',
    open: 'Открыта',
    completed: 'Завершен',
    briskly_business: 'Брискли Бизнесс',
    export_supply: 'Экспорт поставки',
    export: 'Экспортировать',
    supplies: 'Поставки',
    seconds: 'сек',
    terminal: 'Терминал',
    creation_successful: 'Создание успешно',
    date_time: 'Дата и время',
    movements: 'Перемещения',
    write_offs: 'Списания',
    min_size_format: 'Минимальный размер и формат',
    upload: 'Загрузить',
    discount_parameters: 'Параметры скидки',
    unit_of_measure: 'Единица измерения',
    discount_size: 'Размер скидки',
    rounding_type: 'Тип округления',
    round_precision: 'Точность округления',
    days_of_week: 'Дни недели',
    daily: 'Ежедневно',
    or: 'или',
    select_category: 'Выберите категорию...',
    select_catalog: 'Выберите каталог',
    show_all: 'Показать все',
    discount_type: 'Тип скидки',
    y_plural: 'ы',
    select_date: 'Выберите дату',
    choose: 'Выбрать',
    responsible_person: 'Ответственный',
    add: 'Добавить',
    write_off_reason: 'Причина списания',
    select_employee: 'Выберите сотрудника',
    employee_name: 'Имя сотрудника',
    select_trade_point: 'Выберите торговую точку',
    edit: 'Редактировать',
    return_to_list: 'Вернуться к списку',
    order_amount: 'Сумма заказа',
    goods_selected: 'Выбрано товаров',
    creation: 'Создание',
    completed_success: 'успешно завершено',
    creation_error: 'Ошибка при создании',
    editing: 'Редактирование',
    editing_error: 'Ошибка при редактировании',
    no_catalog_trade_point: 'Не выбран каталог в торговой точке!',
    add_point: 'Добавить точку',
    select_trade_points: 'Выберите торговые точки',
    apply: 'Применить',
    enter_title: 'Введите наименование',
    to_list: 'К списку',
    validity_period: 'Время действия',
    catalogs_categories_empty: 'Список ваших каталогов и категорий пока пуст.',
    and: 'и',
    age_limit: 'Возрастное ограничение',
    add_device: 'Добавить устройство',
    save_completed_success: 'Сохранение успешно завершено!',
    enter_description: 'Введите описание',
    application_order: 'Порядок применения',
    schedule: 'Расписание',
    catalog_selection: 'Выбор каталога',
    set_name: 'Название набора',
    title: 'Заголовок',
    good_photo: 'Фото товара',
    total_discount: 'Общая скидка',
    first_purchase_no_time: 'Первая покупка без учета времени',
    first_purchase_limit: 'Первая покупка после назначенного временного лимита',
    full_account_access: 'Доступ ко всему функционалу личного кабинета',
    micromarket_features: 'Доступна возможность открывать микромаркет, менять товар, делать фото, загружать фото в интерфейс курьера',
    process_orders: 'Принимает заказы покупателей и собирает их в торговом зале для отправки клиенту',
    attention: 'Внимание!',
    skip: 'Пропустить',
    selected: 'Выбрано',
    tax_id: 'ИНН',
    bik: 'БИК',
    registration_number: 'ОГРН',
    swift_bik: 'SWIFT / БИК',
    bank_name: 'Наименование банка',
    tax_id_or_name: 'ИНН или наименование юридического лица',
    continue: 'Продолжить',
    minimum_length: 'Минимальная длина',
    characters: 'символов',
    organization_name: 'Название организации',
    tax_reason_code: 'КПП',
    legal_form_code: 'ОКОПФ',
    activity_code: 'ОКВЭД',
    taxation_system: 'Система налогообложения',
    full_name: 'ФИО',
    passport: 'Паспорт',
    series: 'Серия',
    issue_date: 'Дата выдачи',
    division_code: 'Код подразделения',
    date_of_birth: 'Дата рождения',
    place_of_birth: 'Место рождения',
    citizenship: 'Гражданство',
    registration_address: 'Адрес регистрации',
    first_page: 'Первая страница',
    registered_address: 'Стр. с пропиской',
    back: 'Назад',
    not_filled: 'Не заполнено',
    patronymic: 'Отчество',
    issued_by: 'Кем выдан',
    organization_data: 'Данные организации',
    registered_capital: 'Уставной капитал',
    organization_phone: 'Телефон организации',
    offline: 'Не в сети',
    good_barcode: 'ШК товара',
    active_good: 'Активный товар',
    fill_barcode: 'Заполните штрихкод...',
    select_option: 'Выберите...',
    enter_value: 'Введите значение',
    upload_good_photo: 'Загрузка фото товара',
    main_data: 'Основные данные',
    create: 'Создать',
    create_catalog: 'Создать каталог',
    currency: 'Валюта',
    select_currency: 'Выберите валюту',
    prefix_product_info: 'По значению этого префикса система распознаёт весовой товар с выделением цифрового кода товара и его веса в граммах. Доступные префиксы — от 20 до 29. Если префикс не указан, то товары будут обработаны, как штучные.',
    weight_good_barcodes: 'Префиксы штрихкода весового товара',
    enter_name: 'Введите название',
    delete: 'Удалить',
    select_column: 'Выберите столбец...',
    generate_valid_barcode: 'Сформировать валидный ШК автоматически',
    errors_found: 'Найдены ошибки',
    learn_more: 'Подробнее',
    file_uploaded_success: 'Файл успешно загружен!',
    manufacture_date: 'Дата изготовления',
    start: 'Начало',
    total_shelf_life: 'Общий срок годности',
    sticker_quantity: 'Количество наклеек',
    enter_quantity: 'Введите кол-во...',
    for_single_item: 'Для одной позиции',
    saved_successfully: 'Успешно сохранено',
    revenue: 'Выручка',
    average_check: 'Средний чек',
    menu_updated: 'Меню обновлено',
    refund_amount: 'Сумма возврата',
    trade_point_number: 'Торговая точка №',
    serial_number: 'Серийный номер',
    production_date: 'Дата производства',
    location: 'Место нахождения',
    shipping_date: 'Дата отгрузки',
    sender: 'Отправитель',
    arrival_date: 'Дата поступления',
    trade_point_supply: 'Торговая точка поставки',
    supply_composition: 'Состав поставки',
    writeoff_date_time: 'Дата и время списания',
    writeoff: 'Списание',
    promo_materials: 'Промоматериалы',
    from_trade_point: 'Из торговой точки',
    to_trade_point: 'В торговую точку',
    transfer_composition: 'Состав перемещения',
    trade_point_write_off: 'Торговая точка списания',
    write_off_composition: 'Состав списания',
    end: 'Конец',
    completion: 'Завершение',
    return_back: 'Вернуться назад',
    mon: 'Пн',
    tue: 'Вт',
    wed: 'Ср',
    thu: 'Чт',
    fri: 'Пт',
    sat: 'Сб',
    sun: 'Вс',
    sunday: 'Воскресенье',
    monday: 'Понедельник',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    saturday: 'Суббота',
    role_in_company: 'Роль в компании',
    current_quantity: 'Текущее кол-во',
    trade_point_type: 'Тип торговой точки',
    export_trade_points: 'Экспорт торговых точек',
    delete_trade_point: 'Удалить торговую точку',
    activity: 'Активность',
    return_to_discounts: 'Вернуться к скидкам',
    create_discount_done: 'Создание скидки завершено!',
    usage_features: 'Особенности применения',
    discount_empty: 'Раздел скидок пуст',
    category_goods_discounts: 'В данном разделе будут отображены все скидки на товары и категории товаров, настроенные при их создании и редактировании',
    create_new: 'создать новую',
    attach_discount_to_item: 'и прикрепить её к тому или иному товару или категории товаров.',
    you_can_also: 'Так же вы можете',
    create_discount_set: 'создать Набор скидок',
    order_discounts: ', это комплекс скидок, который вы можете применить ко всему заказу целиком в одной или нескольких торговых точках по заданным параметрам.',
    delete_discount: 'Удалить скидку',
    discount_deleted: 'Скидка удалена!',
    discount_status: 'Статус скидки',
    enter_address: 'Введите адрес...',
    auto_generate_reports: 'Автоматически происходит формирование трёх типов отчётов',
    recipient: 'Получатель',
    equipment_transfer_reason: 'Основание для передачи оборудования',
    being_delivered: 'Доставляется',
    accept_to_balance: 'Принять на баланс',
    process_return: 'Оформить возврат',
    total_devices: 'Всего устройств',
    floor: 'Этаж',
    finish: 'Завершить',
    next: 'Далее',
    generate_report: 'Сформировать отчет',
    setup_integration: 'Настроить интеграцию',
    prefix_weight_good: 'По значению этого префикса система распознаёт весовой товар с выделением цифрового кода товара и его веса в граммах',
    available_prefixes: 'Доступные префиксы — от 20 до 29',
    process_goods_individual: 'Если префикс не указан, то товары будут обработаны, как штучные',
    good_status: 'Статус товара',
    deactivated: 'деактивирован',
    blocked: 'заблокирован',
    enter_trade_point_name: 'Введите название торговой точки...',
    trade_point_phone: 'Телефон торговой точки',
    your_logo: 'Ваш логотип',
    set_market_schedule: 'Установите расписание работы вашего микромаркета: в какое время он открывается для покупок, а в какое время блокируется.',
    all_day: 'Круглосуточно',
    day_off: 'Выходной',
    assign: 'Назначить',
    select_trade_logo_appearance: 'Выберите, как будет выглядеть логотип вашей торговой точки в приложении B-Pay.',
    display_in_product_card: 'Он будет отображаться в карточке товара.',
    number: 'Номер...',
    closed_territory: 'Закрытая территория',
    go_back: 'Вернуться',
    no_data: 'Нет данных',
    city: 'Город',
    district: 'Район',
    cost: 'Стоимость',
    to_category: 'В категорию',
    from_category: 'Из категории',
    information: 'Информация',
    humanDateTimeAt: 'dd.MM.yyyy \'в\' HH:mm',
    validation: {
      required: 'Обязательное поле',
      min_length: 'Минимальная длина — {0} символов',
      max_length: 'Максимальная длина — {0} символов',
      invalid_numeric: 'Присутствуют недопустимые символы',
      invalid_email: 'Некорректный адрес',
      invalid_phone: 'Некорректный номер телефона',
      invalid_characters: 'Присутствуют недопустимые символы',
      invalid_uuid_format: 'Не валидный формат UUID',
      invalid_number: 'Не валидный номер',
      invalid_format: 'Неправильный формат',
      invalid_date: 'Некорректная дата',
    },
  }
};
